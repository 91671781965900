<template>
  <div style="margin: 48px">
    <i-button @click="$router.navigateBack"> Terug </i-button>
    <sb-page-header>
      <template v-slot:left>
        <h1>Mijn profiel</h1>
      </template>
      <template v-slot:right>
        <!-- <Dropdown placement="bottom-end">
          <i-button
            type="primary"
            ghost
          >
            Acties
            <Icon type="md-more" />
          </i-button>

          <DropdownMenu
            slot="list"
            style="width: 180px"
          >
            <SbDropdownButton>
              <RouterLink :to="{name: 'ProfileEdit'}">
                Gegevens bewerken
              </RouterLink>
            </SbDropdownButton>
          </DropdownMenu>
        </Dropdown> -->
      </template>
    </sb-page-header>

    <row v-if="me" :gutter="25" style="max-width: 1000px">
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
        <sb-divider title="Persoonlijke informatie" />

        <sb-list>
          <sb-list-item label="ID:">
            {{ me.id }}
          </sb-list-item>
          <sb-list-item label="Naam:">
            {{ me.fullName }}
          </sb-list-item>
          <sb-list-item label="Email:">
            <a :href="`mailto:${me.email}`" target="_blank">{{ me.email }}</a>
          </sb-list-item>
        </sb-list>
      </i-col>
      <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
        <sb-divider title="Gebruiker" />

        <sb-list>
          <sb-list-item label="Gebruikersnaam:">
            {{ me.username }}
          </sb-list-item>
          <sb-list-item label="Wachtwoord:">
            <router-link
              :to="{
                name: 'ForgotPassword',
                params: { username: me.username },
              }"
            >
              Reset
            </router-link>
          </sb-list-item>
          <sb-list-item label="Rol:">
            {{ $user.role }}
          </sb-list-item>
        </sb-list>
      </i-col>

      <template v-if="$user.role === 'MANAGER'">
        <i-col :xs="{ span: 24 }" :lg="{ span: 12 }">
          <sb-divider title="Abonnement" />
          <sb-list>
            <sb-list-item label="Status">
              <template v-if="get(organisation, 'active') != undefined">
                {{ organisation.active ? '🟢 Actief' : '🔴 Inactief' }}
              </template>
            </sb-list-item>

            <sb-list-item label="Startdatum">
              <template v-if="get(organisation, 'startDate')">
                {{ new Date(organisation.startDate).toLocaleDateString() }}
              </template>
            </sb-list-item>

            <sb-list-item label="Loopt af:">
              <template v-if="get(organisation, 'closingDate')">
                {{ new Date(organisation.closingDate).toLocaleDateString() }}
                ({{
                  Math.max(0, getDifferenceInDays(organisation.closingDate))
                }}
                dagen)
              </template>
            </sb-list-item>

            <sb-list-item label="Verlengbeleid">
              <template v-if="get(organisation, 'renewalPolicy')">
                {{ formatRenewalPolicy(organisation.renewalPolicy) }}
              </template>
            </sb-list-item>
          </sb-list>
          <p class="administration-support">
            <span
              >Bij vragen over je abonnement, mail naar
              <a href="mailto:administratie@brightskills.nl"
                >administratie@brightskills.nl</a
              ></span
            >
          </p>
        </i-col>

        <i-col
          v-if="get(organisation, 'parentOrganisation')"
          :xs="{ span: 24 }"
          :lg="{ span: 12 }"
        >
          <sb-divider title="Bovenschoolse organisatie" />
          <sb-list>
            <sb-list-item label="Naam">
              {{ organisation.parentOrganisation.name }}
            </sb-list-item>
            <sb-list-item label="Contact">
              {{ organisation.parentOrganisation.contact.name }}
            </sb-list-item>
            <sb-list-item label="E-mailadres">
              <a
                :href="`mailto:${organisation.parentOrganisation.contact.email}`"
              >
                {{ organisation.parentOrganisation.contact.email }}
              </a>
            </sb-list-item>
            <sb-list-item label="Telefoon">
              <a :href="`tel:${organisation.parentOrganisation.contact.phone}`">
                {{ organisation.parentOrganisation.contact.phone }}
              </a>
            </sb-list-item>
          </sb-list>
        </i-col>
      </template>
    </row>

    <sb-spacer height="100" />
    <sb-divider title="Rondleiding" />
    <button @click="resetTours">
      <sb-button button-type="light" size="regular"
        >Rondleiding(en) nogmaals bekijken</sb-button
      >
    </button>

    <sb-spacer height="400" />
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbList from '@/components/SbList';
import SbListItem from '@/components/SbListItem';
import SbPageHeader from '@/components/SbPageHeader';

import Me from '@/graphql/queries/Me.gql';
import tourDataMixin from '@/mixins/tourDataMixin';
import gql from 'graphql-tag';

export default {
  name: 'Profile',

  components: {
    SbDivider,
    SbList,
    SbListItem,
    SbPageHeader,
  },
  mixins: [tourDataMixin],

  methods: {
    getDifferenceInDays(dateA, dateB = new Date()) {
      if (!dateA || !dateB) return;
      const time = Math.abs(dateB - dateA);
      const days = Math.ceil(time / (1000 * 60 * 60 * 24));
      return days;
    },

    async resetTours() {
      await this.resetAll();
      this.$Modal.success({
        title: 'Rondleidingen zijn gereset',
        content: 'Je kunt opnieuw alle rondleidingen volgen.',
      });
    },

    formatRenewalPolicy(renewalPolicy) {
      if (renewalPolicy === 'MONTH') return 'Maandelijks';
      if (renewalPolicy === 'YEAR') return 'Jaarlijks';
      if (renewalPolicy === 'NONE') return 'Nooit';
      return '-';
    },
  },

  apollo: {
    me: {
      query: Me,
    },

    organisation: {
      skip() {
        return !(this.$user.role === 'MANAGER' && !!this.$user.resourceId);
      },

      query() {
        return gql`query Profile_getOrganisationById {
          getOrganisationById(id: "${this.$user.resourceId}") {
            id
            active
            startDate
            closingDate
            renewalPolicy
            parentOrganisation {
              id
              name
              contact {
                name
                phone
                email
              }
            }
          }
        }`;
      },

      update(data) {
        if (!data?.getOrganisationById) return;
        return {
          ...data.getOrganisationById,
          closingDate: new Date(data.getOrganisationById.closingDate),
        };
      },
    },
  },
};
</script>

<style scoped>
.administration-support {
  margin: 20px 0;
  padding: 10px 10px 10px 25px;
}
</style>
