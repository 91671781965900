<template>
  <div style="margin: 48px">
    <i-button @click="$router.navigateBack"> Terug </i-button>
    Profile Edit
  </div>
</template>

<script>
export default {};
</script>

<style></style>
